<template>
  <div class="dropdown" :class="{ 'is-hoverable': availableChecks.length }">
    <a class="dropdown-trigger">
      <button
        :disabled="loading || !availableChecks.length"
        :class="{ 'is-loading': loading }"
        class="button is-info is-rounded is-small"
      >
        <span>{{ vrm }}</span>
        <span class="icon" v-if="availableChecks.length">
          <i class="fas fa-angle-down" />
        </span>
      </button>
      <div class="dropdown-menu has-arrow is-left">
        <div class="dropdown-content" v-if="!loading">
          <div v-for="(check, index) in availableChecks" :key="index">
            <router-link
              v-if="check.response"
              :to="{
                name: 'autocheck',
                params: { uuid: check.response.uuid }
              }"
              class="dropdown-item"
              @click.native="$mxp.track('autocheck_page_view_batch_check')"
            >
              <span>{{ check.vrm }}</span>
            </router-link>
            <div v-else class="dropdown-item">
              <span class="has-text-grey-light">N/A</span>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckPrevious',
  props: {
    batchChecks: {
      type: Array,
      required: true
    },
    vrm: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    availableChecks() {
      return this.batchChecks.filter(check => check.response)
    }
  }
}
</script>
